<template lang="">
  <div class="map-calendar">
    <van-nav-bar left-arrow @click-left="onClickLeft">
      <template #title>
        <span>案件日历</span>
      </template>
    </van-nav-bar>
    <div class="changeBtn" @click="changeMap">切换到地图</div>
    <div class="calendar-header">
      <van-button icon="arrow-left" @click="changeMonth(-1)"></van-button>
      <van-button icon="arrow" @click="changeMonth(1)"></van-button>
    </div>
    <van-calendar
      class="van-calendar"
      :show-title="false"
      :poppable="false"
      :show-confirm="false"
      :min-date="minDate"
      :formatter="formatter"
      style="height: 400px; overflow: hidden;"
      :default-date="currentDate"
      @confirm="choiesDateConfirm"
    />
    <van-list
      v-model="loading"
      :finished="finished"
      v-if="list.length"
      class="list-box"
    >
      <CaseItem
        v-for="(item, index) in list"
        :item="item"
        :key="index"
        :isAdmin="userBaseInfo.resOperatorDTO.isAdmin"
        :userInfo="userBaseInfo.resOperatorDTO"
        class="item"
        @actionClick="onActionClick"
      ></CaseItem>
    </van-list>
    <PopupForm
      v-model="showPopup"
      :actionType="actionType"
      :actionItem="actionItem"
      @confirmPopup="confirmPopup"
    >
    </PopupForm>
  </div>
</template>
<script>
import Api from "@/api/user";
import { Toast } from "vant";
import popupFormMixin from "@/mixins/popupFormMixin";

export default {
  mixins: [popupFormMixin],
  data() {
    return {
      minDate: new Date(2024, 0, 1),
      // maxDate: new Date(),
      currentDate: new Date(),
      list: [],
      showPopup: false,
      finished: false,
      currentRenderData: [],
      loading: false,
      pageNo: 1,
      pageSize: 100,
    };
  },
  mounted() {
    this.currentDate = new Date();
    this.getSkyCalendarStatistics();
  },
  methods: {
    changeMap() {
      this.$router.replace("/map");
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    /** 日历相关 start **/
    formatter(day) {
      let cur = this.currentRenderData.find(
        (i) => this.formatDate(new Date(i.time)) === this.formatDate(day.date)
      );
      if (cur) {
        day.bottomInfo = cur.count + "笔";
      }
      return day;
    },
    changeMonth(delta) {
      const newDate = new Date(this.formatDate(this.currentDate));
      newDate.setMonth(newDate.getMonth() + delta);
      // if (newDate >= this.minDate && newDate <= this.maxDate) {
      // }
      this.currentDate = newDate;
      this.getSkyCalendarStatistics();
    },
    async onLoad(time = "") {
      try {
        this.loading = true;
        const { pageNo, pageSize } = this;
        const params = {
          pageNo,
          pageSize,
          homeVisitDateEq: time,
          statusEq: "PROCESSING",
        };
        var res = await Api.findPageISO(params);
        const {
          data: { list },
        } = res;
        if (this.pageNo == 1) {
          this.list = list;
        } else {
          this.list = list;
        }
        this.finished = true;
        this.pageNo++;
      } catch (error) {
        Toast.fail(error);
        this.list = [];
      } finally {
        this.loading = false;
      }
    },
    choiesDateConfirm(v) {
      this.list = [];
      this.pageNo = 1;
      this.finished = false;
      this.onLoad(this.formatDate(v));
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    // 获取日历统计数据
    getSkyCalendarStatistics() {
      let data = this.formatDate(this.currentDate).substring(0, 7); // 格式化日期，只取年月
      this.list = [];
      Api.skyCalendarStatistics({ yearMonth: data }).then((res) => {
        this.currentRenderData = (res.data || []).map((i) => ({
          ...i,
          time: i.homeVisitDate,
        }));
      });
    },
    /** 日历相关 end **/
  },
};
</script>
<style lang="less" scoped>
.map-calendar {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100vh;
  .list-box {
    padding-bottom: 20px;
  }
}

.calendar-header {
  display: flex;
  justify-content: space-between;

  /deep/.van-button {
    border: none;
  }
}
.van-calendar {
  margin-top: -45px;

  /deep/.van-calendar__body {
    overflow: hidden;

    .van-calendar__bottom-info {
      color: #3c86ff;
      font-size: 14px;
    }
  }
}
.changeBtn {
  position: fixed;
  bottom: 6rem;
  right: 1.25rem;
  width: 3.75rem;
  height: 3.75rem;
  line-height: 1.3;
  text-align: center;
  border-radius: 50%;
  background-color: #3c86ff;
  color: #fff;
  font-size: 0.75rem;
  cursor: pointer;
  z-index: 1999;
  padding: 0px 9px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  display: flex;
}
</style>
